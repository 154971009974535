// Packages
import React from "react";
import { graphql } from "gatsby";

// Components
import Container from "~components/common/Container";
import Layout from "~components/layouts/Layout";
import H1 from "~components/typography/H1";

// Types
import type { SiteMetadata, GatsbyImagePropsMeta, Location } from "~types/Gatsby";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata,
	};
	seoImage: {
		facebook: GatsbyImagePropsMeta,
		twitter: GatsbyImagePropsMeta,
	};
}

export default function Page({ data, location }: { data: DataProps, location: Location }) {
	return (
		<Layout
			location={location}
			title="404"
			desc="Leider existiert dieser Link nicht"
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			noIndex
		>
			<Container>
				<H1 className="mb-8">Seite nicht gefunden</H1>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		seoImage: file(relativePath: { eq: "placeholder-meta.png" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}
	}
`;
